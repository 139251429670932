import React, {useState} from "react"
import {useDispatch} from "react-redux"

import SRModal from "../../../components/modal/modal"
import {removeMember} from "../../../redux/group/groupAction";

const RemoveMemberModal = ({member}) => {
    const dispatch = useDispatch()
    const [modalIsOpen, setModalIsOpen] = useState(true)

    const onOpenModal = () => {
        setModalIsOpen(true)
    }

    const onCloseModal = () => {
        setModalIsOpen(false)
    }

    const onYes = () => {
        dispatch(removeMember(member))
        onCloseModal()
    }

    const onNo = () => {
        onCloseModal()
    }

    return (
        <SRModal text={"Supprimer"}
                 title={"Supprimer un membre"}
                 className={"removeMember addMember"}
                 onOpen={onOpenModal}
                 onClose={onCloseModal}
                 isOpen={modalIsOpen}>
        <div className={"content content-modal text-center"}>
            <p>Are you sure ?</p>
            <button onClick={onYes} className={"btn btn-sm btn-success mx-2"}>Oui</button>
            <button onClick={onNo} className={"btn btn-sm btn-warning mx-2"}>Non</button>
        </div>
        </SRModal>
    )
}

export default RemoveMemberModal
