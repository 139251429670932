import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import {useDispatch, useSelector} from "react-redux"

import CheckboxInput from "../welcome/checkboxInput"
import BankTransferModal from "./bankTransferModal"
import CreditCardModal from "./creditCardModal"
import RefundModal from "./refundModal"
import {isDefined} from "../../../utils/data"
import {onChange, createGroup} from "../../../redux/group/groupAction"
import TermsOfSales from "../../../components/termsOfSales/termsOfSales2"

const Payment = ({total}) => {
    const dispatch  = useDispatch()
    const  data   = useSelector(({groupReducer}) => groupReducer)
    const {coordinator, stripe}  = useSelector(({groupReducer}) => groupReducer)
    const {members}  = useSelector(({groupReducer}) => groupReducer)    
    const  {information}   = useSelector(({groupReducer}) => groupReducer)

    const registration = (payment = false) => {
        dispatch(createGroup(data, payment === true ? 1 : 0))
    }

    return (
        <div className={"sr-card summary-block payment"}>
        {members.length > 1 ?
    <>

        {isDefined(coordinator) ? 
            <div className={"summary-header"}>
                <a className={"btn btn-sm btn-warning"} href={"https://phc.urevent.fr/documents/group_pro_forma/" + coordinator.group.idgroup} target="_blank">Télécharger la facture</a>
            </div>
            :
            <>
                <div className={"summary-header pb-3"}>
                Après avoir enregistré votre groupe, vous recevrez automatiquement votre facture.
                </div>
            </>
        }
            <hr/>
            <div className={"summary-body"}>
            {!isDefined(coordinator) ? 
                <>
                    <CheckboxInput placeholder={"By checking this box, you accept the"}
                       onChange={e => dispatch(onChange(e, true))}
                       name={"group_cgv"}
                       value={information.group_cgv.value}
                       required={true}/>
                    <div className={"row"}>
                        <TermsOfSales />
                    </div>
                </>
                : ""
            }
                {isDefined(coordinator) ? isDefined(coordinator.group.group_payments) ?
                    parseFloat(coordinator.group.group_payments) < parseFloat(total) ?
                        <>
                            <div className={"row title-payment"}>
                                Paiement
                            </div>
                            <div className={"row"}>
                                <button className={"btn btn-end"}><CreditCardModal stripe={stripe} total={total}/></button>
                            </div>
                            <div className={"row"}>
                                    ou
                                </div>
                                <div className={"row p-3"}>
                                    <Link to={"/confirmation-record"}>
                                        <button className={"btn btn-info mb-2"} onClick={() => registration()}>Enregistrer le groupe</button>
                                    </Link>
                                </div>    
                        </>
                        :
                        parseFloat(coordinator.group.group_payments) > parseFloat(total) ?
                            <>
                                <div className={"row title-payment"}>
                                    Remboursement
                                </div>
                                <div className={"row"}>
                                    <button className={"btn btn-end"}><RefundModal/></button>
                                </div>
                <div className={"row"}>
                                    ou
                                </div>
                                <div className={"row p-3"}>
                                    <Link to={"/confirmation-record"}>
                                        <button className={"btn btn-info mb-2"} onClick={() => registration()}>Enregistrer le groupe</button>
                                    </Link>
                                </div>    
                            </>
                            : "" : "" : 
                    <>

                      
                                <div className={"row mt-3 p-3"}>
                                    <button className={"btn btn-end"}><CreditCardModal stripe={stripe} total={total}/></button>
                                </div>
                                <div className={"row"}>
                                    ou
                                </div>
                                <div className={"row p-3"}>
                                    <Link to={"/confirmation-record"}>
                                        <button className={"btn btn-info mb-2"} onClick={() => registration()}>Enregistrer le groupe</button>
                                    </Link>
                                </div>                                               
                
                    </>
            }
            </div>
            <hr/>
            <div className={"summary-footer"}>
                <div className={"row"}>
                    <TermsOfSales />
                </div>
            </div>
               
</>
: 
<>

            <div className={"summary-header"}>
            Votre groupe doit contenir au moins deux membres avant de pouvoir être enregistré.
            </div>
</>}
        </div>
    )
}

Payment.propTypes = {
    total: PropTypes.string.isRequired,
}

export default Payment
