import React from "react"
import PropTypes from "prop-types"
import { FaUser, FaPhone, FaEnvelope, FaBuilding, FaMapMarkerAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux"

import { onChange, submitInformation } from "../../redux/group/groupAction"
import CountriesInput from "./information/countriesInput"
import PhoneInput from "../../components/form/phoneInput"
import HelpUr from "../../components/help/help"
import { isDefined } from "../../utils/data"

const Information = ({ prevTab, nextTab }) => {
    const dispatch = useDispatch()
    const { information, onEdit } = useSelector(({ groupReducer }) => groupReducer)

    const disabledInput = isDefined(onEdit) ? onEdit : false

    const onSubmit = (e = null) => {
        e.preventDefault()
        const form = e.currentTarget
        form.myButton.disabled = true

        if (form.checkValidity() === false) {
            form.myButton.disabled = false
            e.stopPropagation()
        }

        dispatch(submitInformation(onEdit, information, nextTab))
    }

    return (
        <div className={"content about"}>

            <p className={"title"}>
                Bienvenu sur l'espace d'inscription Groupe CFP 2022
            </p>
            <p className={"important-infos"}>L'inscription groupe est accessible à partir de 2 participants et plus.<br />Pour démarrer le processus d'inscription de groupe, vous aurez besoin d'un coordinateur de groupe.</p>

            <p>Merci de compléter les informations ci-dessous</p>

            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-4 col-lg-3 input-group"}>
                            <label><FaUser /></label>
                            <select className="form-control" name={"group_gender"} value={information.group_gender.value} onChange={e => dispatch(onChange(e))} required>
                                <option value="" disabled selected>Titre *</option>
                                <option>Dr</option>
                                <option>Prof</option>
                                <option>M.</option>
                                <option>Mme</option>
                                <option>Mlle</option>
                            </select>
                            {information.group_gender.error !== "" ?
                                <div className="invalid-tooltip">{information.group_gender.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-5 input-group"}>
                            <label><FaUser /></label>

                            <input type="text" className="form-control" placeholder="Prénom du coordinateur *" name={"group_firstname"} value={information.group_firstname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required />
                            {information.group_firstname.error !== "" ?
                                <div className="invalid-tooltip">{information.group_firstname.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaUser /></label>
                            <input type="text" className="form-control" placeholder="Nom du coordinateur *" name={"group_lastname"} value={information.group_lastname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required />
                            {information.group_lastname.error !== "" ?
                                <div className="invalid-tooltip">{information.group_lastname.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaEnvelope /></label>
                            <input type="email" className="form-control" placeholder="Email de contact *" name={"group_mail"} value={information.group_mail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required />
                            {information.group_mail.error !== "" ?
                                <div className="invalid-tooltip">{information.group_mail.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaEnvelope /></label>
                            <input type="email" className="form-control" placeholder="Confirmez l'Email" name={"confirmEmail"} value={information.confirmEmail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required />
                            {information.confirmEmail.error !== "" ?
                                <div className="invalid-tooltip">{information.confirmEmail.error}</div>
                                : ""}
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-12 input-group"}>
                            <label><FaBuilding /></label>
                            <input type="text" className="form-control" placeholder="Nom du groupe *" name={"group_society_name"} value={information.group_society_name.value} onChange={e => dispatch(onChange(e))} required />
                            {information.group_society_name.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_name.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaMapMarkerAlt /></label>
                            <input type="text" className="form-control" placeholder="Adresse de facturation" name={"group_society_adress"} value={information.group_society_adress.value} onChange={e => dispatch(onChange(e))} required />
                            {information.group_society_adress.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_adress.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaMapMarkerAlt /></label>
                            <input type="text" className="form-control" placeholder="Complément d'adresse" name={"group_society_adress2"} value={information.group_society_adress2.value} onChange={e => dispatch(onChange(e))} required={information.group_society_adress2.required} />
                            {information.group_society_adress2.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_adress2.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt /></label>
                            <input type="text" className="form-control" placeholder="Code postal" name={"group_society_cp"} value={information.group_society_cp.value} onChange={e => dispatch(onChange(e))} required />
                            {information.group_society_cp.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_cp.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt /></label>
                            <input type="text" className="form-control" placeholder="Ville" name={"group_society_city"} value={information.group_society_city.value} onChange={e => dispatch(onChange(e))} required />
                            {information.group_society_city.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_city.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt /></label>
                            <CountriesInput value={information.group_society_country.value}
                                onChange={e => dispatch(onChange(e))}
                                inputCountry={"group_society_country"}
                                inputPhone={"group_society_phone"}
                            />
                            {information.group_society_country.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_country.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaPhone /></label>
                            <PhoneInput value={information.group_society_phone.value} onChange={e => dispatch(onChange(e))} name={"group_society_phone"} />
                            {information.group_society_phone.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_phone.error}</div>
                                : ""}
                        </div>
                    </div>
                </div>
                <div className={"content-footer"}>
                    <small className={"help"}><HelpUr /></small>
                    <button type={"submit"} name={"myButton"} value={"Next"} className={"sr-btn-next btn btn-info"}>Suivant</button>
                </div>
            </form>
        </div>
    )
}

Information.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default Information
